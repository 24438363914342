import { useLazyQuery } from "@apollo/client";
import { clientCentralApi } from "@newrai/api-clients";
import { notification } from "antd";
import { routes } from "config/routes";
import useStore from "config/store";
import { GET_ACCOUNTING_FIRM, GET_COMPANY } from "queries/centralapi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function useCompanySelection() {
  const { t } = useTranslation();
  const userData = useStore(state => state.userData);
  const { companyID } = useParams();

  const setPreviewSrc = useStore(state => state.setPreviewSrc);
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const [isCompanySelectOpen, setIsCompanySelectOpen] = useState(false);

  const [getCompany] = useLazyQuery<GetCompanyResponseType, GetCompanyInputType>(GET_COMPANY, {
    client: clientCentralApi,
    onCompleted: ({ getCompany }) => {
      setIsCompanySelectOpen(false);

      if (getCompany) {
        setIsCompanySelectOpen(false);
        setCompanySelected({
          accountingFirm: getCompany?.accountingFirm,
          accountingFirmId: getCompany?.accountingFirm?.id,
          company: getCompany,
          companyId: getCompany?.id,
        });
        routes.navigate(`/company/${getCompany?.id}`);
      } else {
        routes.navigate("/");
        notification.error({
          message: t("Company not found"),
        });
      }
    },
  });

  const [getAccountingFirm] = useLazyQuery<
    GetAccountingFirmResponseType,
    GetAccountingFirmInputType
  >(GET_ACCOUNTING_FIRM, {
    client: clientCentralApi,
    onCompleted: ({ getAccountingFirm }) => {
      setCompanySelected({
        accountingFirm: getAccountingFirm,
        accountingFirmId: getAccountingFirm?.id,
      });
    },
  });

  useEffect(() => {
    if (userData) {
      const { companies, accountingFirms } = userData;
      const companyId = (companies && companies.length === 1 && companies[0]) || companyID;
      const accountingFirmId =
        accountingFirms && accountingFirms.length === 1 && accountingFirms[0];

      if (companyId) {
        getCompany({
          variables: {
            companyID: companyId,
          },
        });
      } else if (accountingFirmId) {
        getAccountingFirm({
          variables: {
            accountingFirmId: accountingFirmId,
          },
        });
      }
      setIsCompanySelectOpen(true);
    } else {
      routes.navigate(`/error`, { replace: true });
    }
  }, [companyID]);

  const saveCompany = async (companyData: CompaniesWithAccountingFirm) => {
    if (companyData.id) {
      setCompanySelected({
        accountingFirm: companyData.accountingFirm!,
        accountingFirmId: companyData?.accountingFirm?.id,
        company: companyData,
        companyId: companyData.id,
      });
      setIsCompanySelectOpen(false);
      setPreviewSrc([]);

      routes.navigate(`/company/${companyData.id}`);
    }
  };

  return { isCompanySelectOpen, saveCompany };
}
