import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "@newrai/ant-design";
import { clientNeoAuth } from "@newrai/api-clients";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale/en_GB";
import fr_FR from "antd/lib/locale/fr_FR";
import i18n from "config/i18n/i18n";
import { routes } from "config/routes";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import "resources/styles/main.less";
import { Locale } from "~antd/lib/locale-provider";

const CONFIG_TRANSLATIONS: Record<string, Locale> = {
  fr: fr_FR,
  en: en_GB,
};
dayjs.locale(i18n.language as "fr" | "en");

export default function App(): JSX.Element {
  return (
    <ApolloProvider client={clientNeoAuth}>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider locale={CONFIG_TRANSLATIONS[i18n.language]}>
          <ErrorBoundary>
            <DndProvider backend={HTML5Backend}>
              <RouterProvider
                router={routes}
                future={{
                  v7_startTransition: true,
                }}
              />
            </DndProvider>
          </ErrorBoundary>
        </ConfigProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
}
