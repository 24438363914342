import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import HeaderWrapperMemo from "./components/HeaderWrapper";
import LoadingPage from "./components/LoadingPage";

export default function Base(): JSX.Element {
  return (
    <div className="layout-wrapper layout-overlay">
      <HeaderWrapperMemo />
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
