import { AccountingFirmsOptions } from "@newrai/ant-design";
import { clientCentralApi } from "@newrai/api-clients";
import { SEARCH_LIMIT } from "config/settings";
import { LIST_ACCOUNTING_FIRMS_QUERY, LIST_COMPANIES_QUERY } from "queries/centralapi";
import { createAccountingFirmOption, createCompanyOption } from "./selectOptions";

export const loadAccountingFirmsOptions = async (
  search = "",
  prevOptions: Record<string, unknown>[],
): Promise<Record<string, unknown>> => {
  const variables = {
    limit: SEARCH_LIMIT,
    offset: prevOptions.length,
    filter: {
      companies_Isnull: false,
      name_Icontains: search,
    },
  };

  const response = await getMoreData({
    query: LIST_ACCOUNTING_FIRMS_QUERY,
    dataName: "listAccountingFirmsPaginated",
    variables,
  });
  const resultsList = response.results;

  const filteredList = resultsList.filter(
    (item: { companies: Companies[] }) => item.companies.length > 0,
  );
  const options = filteredList.map((accountingFirmItem: { name: string }) => {
    return createAccountingFirmOption(accountingFirmItem);
  });

  const hasMore = resultsList.length < response.totalCount;
  return { options, hasMore };
};

export const loadCompaniesOptions = async (
  search = "",
  prevOptions: Record<string, unknown>[],
  accountingFirms: AccountingFirmsOptions | undefined,
): Promise<Record<string, unknown>> => {
  const selectedAccountingFirmId = accountingFirms?.selectedOption?.value?.id;
  const accountingFirmId = Number(selectedAccountingFirmId);
  const variables = {
    limit: SEARCH_LIMIT,
    offset: prevOptions.length,
    filter: {
      accountingFirm: accountingFirmId,
      nameOrInternalReference: search,
    },
  };

  const response = await getMoreData({
    query: LIST_COMPANIES_QUERY,
    dataName: "listCompaniesPaginated",
    variables,
  });
  const resultsList = response.results;
  const options = resultsList.map((companyItem: CompanyItem) => createCompanyOption(companyItem));

  const hasMore = resultsList.length < response.totalCount;
  return { options, hasMore };
};

const getMoreData = ({ query, dataName, variables }: GetMoreData): Promise<any> => {
  return clientCentralApi
    .query({
      query,
      variables,
    })
    .then(({ data }: { data: Record<string, any> }) => {
      return data[dataName];
    });
};
