export const createCompanyOption = (item: Record<string, any>): { label: string; value: any } => {
  return {
    label: `${item.internalReference} - ${item.name}`,
    value: item,
  };
};

export const createAccountingFirmOption = (
  item: Record<string, any>,
): { label: string; value: any } => {
  return {
    label: item.name,
    value: item,
  };
};
