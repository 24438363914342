import { createStore, setStateHelper } from "@newrai/ant-design";
import { produce } from "immer";
import { BookTypeStore, CachedImages, FileStore, StoreState } from "src/types/store";
import { StoreApi, UseBoundStore } from "zustand";

export const initialState = {
  documents: [],
  totalDocuments: 0,
  documentsSelected: [],
  bookTypes: [],
  bookTypeSelected: undefined,
  companySelected: {
    company: undefined,
    accountingFirm: undefined,
  },
  userData: undefined,
  cachedImages: {},
  showUploadList: false,
  filesToUpload: [],
  totalFilesUploaded: 0,
  selectedIndex: undefined,
  isLoading: false,
  previewSrc: [],
  documentSplitType: "DEFAULT",
  acceptedFileTypes: [],
};

const useStore: UseBoundStore<StoreApi<StoreState>> = createStore<StoreState>(
  (set: (fn: (draft: Record<string, any>) => void) => void, get: any) => ({
    ...initialState,
    resetStore: () => {
      set(() => ({
        ...initialState,
      }));
    },
    setUserData: (userData: getUserByToken) => {
      setStateHelper("userData", userData, set, get);
    },
    setCompanySelected: (companySelected: Company) => {
      setStateHelper("companySelected", companySelected, set, get);
    },
    setBookTypes: (bookTypes: BookTypeStore[]) => {
      setStateHelper("bookTypes", bookTypes, set, get);
    },
    incrementBookTypesTotal: (bookTypeId: string, total: number) => {
      const bookTypes = get().bookTypes;
      const index = bookTypes.findIndex((item: BookTypeStore) => item.bookType === bookTypeId);
      set(state => ({
        ...state,
        bookTypes: produce(bookTypes, (draft: BookTypeStore[]) => {
          draft[index].documentCount += total;
        }),
        totalDocuments: state.totalDocuments + total,
      }));
    },
    setBookTypeSelected: (bookTypeSelected: BookTypeStore) => {
      setStateHelper("bookTypeSelected", bookTypeSelected, set, get);
    },
    setDocuments: (documents: DocumentItem[]) => {
      setStateHelper("documents", documents, set, get);
    },
    setTotalDocuments: (totalDocuments: number) => {
      setStateHelper("totalDocuments", totalDocuments, set, get);
    },
    setDocumentsSelected: (documentsSelected: DocumentItem[]) => {
      setStateHelper("documentsSelected", documentsSelected, set, get);
    },
    setDocumentsAllSelected: () => {
      set(state => ({
        ...state,
        documentsSelected: state.documents,
      }));
    },
    setCachedImages: (cachedImages: CachedImages) => {
      setStateHelper("cachedImages", cachedImages, set, get);
    },
    setShowUploadList: (showUploadList: boolean) => {
      setStateHelper("showUploadList", showUploadList, set, get);
    },
    setFilesToUpload: (filesToUpload: FileStore[]) => {
      setStateHelper("filesToUpload", filesToUpload, set, get);
    },
    resetFilesToUpload: () => {
      set(state => ({
        ...state,
        filesToUpload: [],
      }));
    },
    updateFileStatus: (fileIndex: number, status: string) => {
      const filesToUpload = get().filesToUpload;
      if (filesToUpload.length < 1) {
        return;
      }

      set(state => ({
        ...state,
        filesToUpload: produce(filesToUpload, (draft: FileStore[]) => {
          draft[fileIndex].status = status;
        }),
      }));
    },
    incrementTotalFilesUploaded: () => {
      set(state => ({
        ...state,
        totalFilesUploaded: state.totalFilesUploaded + 1,
      }));
    },
    resetTotalFilesUploaded: () => {
      set(state => ({
        ...state,
        totalFilesUploaded: 0,
      }));
    },
    setSelectedIndex: (selectedIndex: number) => {
      setStateHelper("selectedIndex", selectedIndex, set, get);
    },
    setIsLoading: (isLoading: boolean) => {
      setStateHelper("isLoading", isLoading, set, get);
    },
    setPreviewSrc: (previewSrc: string) => {
      setStateHelper("previewSrc", previewSrc, set, get);
    },
    setDocumentSplitType: (documentSplitType: DocumentSplitType) => {
      setStateHelper("documentSplitType", documentSplitType, set, get);
    },
    setAcceptedFileTypes: (acceptedFileTypes: string[]) => {
      setStateHelper("acceptedFileTypes", acceptedFileTypes, set, get);
    },
  }),
);

export default useStore;
