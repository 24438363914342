import { HeaderWithOptions } from "@newrai/ant-design";
import {
  clientCentralApi,
  clientNeoActivite,
  clientNeoActiviteWS,
  clientNeoAuth,
} from "@newrai/api-clients";
import { Layout } from "antd";
import axios from "axios";
import { langsCode, langsLabel } from "config/i18n/i18n";
import { routes } from "config/routes";
import Settings, { APP_URL } from "config/settings";
import useStore from "config/store";
import useCompanySelection from "hooks/useCompanySelection";
import i18n from "i18next";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NeoScanLogo from "resources/images/neoscan.png";
import { loadAccountingFirmsOptions, loadCompaniesOptions } from "utils/selectCompanyHelpers";
import { createAccountingFirmOption, createCompanyOption } from "utils/selectOptions";
import AppsMenu from "./AppsMenu";

function HeaderWrapper(): React.ReactElement {
  const { t } = useTranslation();
  const { companyID } = useParams();
  const [language, setLanguage] = useState(i18n.language);
  const userData = useStore(state => state.userData);
  const isNewrai = userData?.isNewraiUser;
  const resetStore = useStore(state => state.resetStore);
  const companySelected = useStore(state => state.companySelected);
  const { isCompanySelectOpen, saveCompany } = useCompanySelection();

  const changeLanguage = (lang: string): void => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const handleLogout = async () => {
    const resetStores = (): void => {
      clientNeoAuth.resetStore();
      clientCentralApi.resetStore();
      resetStore();
    };

    const resetCookies = (): void => {
      Cookies.remove("access_token");
      Cookies.remove("expireDate");
    };

    await axios(Settings.revokeToken, {
      method: "post",
      data: `token=${Cookies.get("access_token")}&client_id=${Settings.clientId}`,
    }).then(() => {
      resetStores();
      resetCookies();
      routes.navigate("/");
    });
  };

  const languageOptions =
    process.env.NODE_ENV === "development"
      ? {
          changeLanguage: (e: string): void => changeLanguage(e),
          languageCodes: langsCode,
          languageLabels: langsLabel(),
          selectedLanguage: language,
        }
      : undefined;

  const canUpdateCompany =
    userData?.permissions &&
    userData?.permissions?.find((item: string) => item === "dashboard.can-display-companies");

  return (
    <Layout>
      <AppsMenu />
      <HeaderWithOptions
        avatarOptions={{
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          userCategory: userData?.userCategory?.name,
          onLogout: handleLogout,
        }}
        {...(companyID && canUpdateCompany
          ? {
              goToDashboardOptions: {
                url: `${Settings.dashboard}companies/${companyID}/general/general-info`,
              },
            }
          : {})}
        languageOptions={languageOptions}
        logoOptions={{
          src: NeoScanLogo,
          alt: "NeoScan Pro",
        }}
        selectCompanyOptions={{
          accountingFirmValue: companySelected?.accountingFirm,
          companyValue: companySelected?.company,
          isModalOpen: isCompanySelectOpen,
          loadAccountingFirmsOptions: loadAccountingFirmsOptions,
          loadCompaniesOptions: loadCompaniesOptions,
          onSave: (companyData: CompaniesWithAccountingFirm): void => {
            saveCompany(companyData);
          },
          selectedAccountingFirmsOptionFormat: createAccountingFirmOption,
          selectedCompanyOptionFormat: createCompanyOption,
          translations: {
            title: {
              dialog: t("Select company"),
              accompanyFirmDropdown: t("Accounting Firm"),
              companyDropdown: t("Company"),
            },
            button: {
              close: t("Close"),
              save: t("Save"),
            },
            loadingMessage: `${t("Loading")}...`,
            noOptionsMessage: t("No options"),
            placeholder: `${t("Select")}...`,
          },
          isEditable: true,
        }}
        freshDeskWidget={{
          autofill: {
            requester: userData?.email,
            name: userData?.firstName,

            subject: companySelected
              ? `${companySelected?.accountingFirm?.name} - ${companySelected?.company?.internalReference}: ${companySelected?.company?.name}
            `
              : "",
          },
          formTitle: t("Client support"),
          isNewrai: isNewrai,
        }}
        neoactivite={{
          neoActiviteClientHttp: clientNeoActivite,
          neoActiviteClientWS: clientNeoActiviteWS,
          neoActiviteUrl: APP_URL.neoActiviteUrl,
        }}
      />
    </Layout>
  );
}

const HeaderWrapperMemo = React.memo(HeaderWrapper);

export default HeaderWrapperMemo;
